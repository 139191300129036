<script setup lang="ts">
import kebabCase from 'lodash/kebabCase'
import { useClipboard } from '@vueuse/core'
import type { VueInstance } from '@vueuse/core'
import { Field, ErrorMessage, useFieldError, validate } from 'vee-validate'
import Icon from '~/components/common/Icon.vue'

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    default: 'text',
  },
  placeholder: {
    type: String,
    required: false,
  },
  isFocused: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
    required: true,
  },
  modelValue: {
    type: String,
    required: true,
  },
  rules: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: false,
  },
  validateOnBlur: {
    type: Boolean,
    default: true,
  },
  defaultValue: {
    type: String,
    default: '',
  },
  enableCopyToClipboard: {
    type: Boolean,
    default: false,
  },
})

const error = useFieldError(props.name)

const id = useId()
const typeRef = ref(props.type)
const field = ref<VueInstance>()

const hasError = computed(() => error.value)
const formInputTestId = computed(() => kebabCase(`form-input-${props.label}`))
const { copy, copied } = useClipboard()

const visibleType = computed(() => typeRef.value === 'text')
const isEmail = computed(
  () => props.type === 'email' || props.rules.match(/email/),
)

const toggleType = () => {
  typeRef.value = typeRef.value === 'password' ? 'text' : 'password'
}

const emit = defineEmits(['update:modelValue'])

const handleUpdateValue = (value: string) => emit('update:modelValue', value)

watch(
  () => props.modelValue,
  async (value) => {
    if (!isEmail.value) return

    const { valid } = await validate(value, props.rules)

    if (valid) {
      field.value.$el.dispatchEvent(new Event('blur'))
    }
  },
)

onMounted(() => {
  props.isFocused && field.value.$el.focus()

  if (props.defaultValue) {
    handleUpdateValue(props.defaultValue)
  }
})
</script>

<template>
  <div :data-testid="formInputTestId">
    <div class="mb-1">
      <label :for="id" class="mb-1 block text-xs font-semibold">
        {{ label }} <span v-if="required" class="text-error">*</span>
      </label>

      <div
        class="focus-within:ring-p-500 w-full border border-s-500 focus-within:border-transparent rounded focus-within:ring-1 flex items-center gap-x-1"
      >
        <Field
          :id="id"
          ref="field"
          data-testid="field"
          :name="name"
          :rules="rules"
          class="w-full border-none !outline-0 focus:ring-0 rounded !shadow-none text-grey-900 border-transparent py-1"
          :class="{
            'border-error': hasError,
            'border-s-600': !hasError,
          }"
          :type="typeRef"
          :placeholder="placeholder"
          :validate-on-blur="validateOnBlur"
          :validate-on-input="!isEmail"
          :label="label"
          :value="modelValue"
          @update:model-value="handleUpdateValue"
        />

        <button
          v-if="enableCopyToClipboard"
          data-testid="copy-to-clipboard-button"
          class="w-7 h-7 flex items-center justify-center rounded"
          :class="{
            'bg-p-50 border-[1.5px] border-p-700': copied,
          }"
          @click="copy(modelValue)"
        >
          <Icon class="text-s-900" name="icon-copy-alt" />
        </button>

        <div v-if="type === 'password'" class="text-s-900">
          <div
            class="flex h-7 w-7 cursor-pointer items-center"
            @click="toggleType"
          >
            <Icon v-show="visibleType" name="icon-eye-off" />
            <Icon v-show="!visibleType" name="icon-eye-on" />
          </div>
        </div>
      </div>
    </div>

    <div class="h-4">
      <ErrorMessage class="text-xxs text-error block" :name="name" />
    </div>
  </div>
</template>
